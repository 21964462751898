
.main {
  background-color:#21263f;
  height: 100%;
}

.title{
  color:white;
  font-size: 50px;
  text-align: center;
  border-bottom: 4px solid white;
}

.table_ranking{
  color:white;
  font-size: 35px;
}

.points{
  text-align: center;
}

.name{
  text-align: left;
  padding-left:0px !important;
  padding-right:0px !important;
  font-size: 25px;
  vertical-align: middle;
  padding-top:8px;
  max-height: 50px;
}

.rank{
  text-align: center;
  padding-right:0px !important;
  padding-left:0px !important;
}

.bigcoloneMiddle{
  border-right: 1px solid white;
}

.cont{
  padding:5 !important;
}

.line{
  padding-top:10px;
  padding-bottom:10px;
  border-bottom: 1px solid white;
  max-height:90px;
}
.imgContainer{
  background-color: white;
}
.lineYellow{
  background-color: white;
  width:100%;
  height:4px;
  margin-top: 30px;
}
.leaders{
  margin-top:30px;
  border-top: 1px solid white;
  background-color: #0b0d16;
}